<template>
    <v-container fluid>
        <v-row no-gutters><v-col><v-icon @click="back" :color="$store.getters.getColorPalette().accentCode" small>mdi-arrow-left</v-icon></v-col></v-row>
        <component v-if="view" v-bind:is="viewHash[view.view_type]" :view="view"></component>
        <v-row v-else>
            <v-col>
                <div 
            :style="{'text-align':'center'}">
            <v-icon large>mdi-hail</v-icon>
            <h1>{{$store.getters.getTextMap().not_configured}}</h1>
        </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import TableViewPage from '@/pages/views/TableViewPage'
import WidgetViewPage from '@/pages/views/WidgetViewPage'
import DynamicTimeframedWidgetView from '@/pages/views/DynamicTimeframedWidgetView'
import EnergyUsageViewPage from '@/pages/views/EnergyUsageViewPage'
import MachineScrollViewPage from '@/pages/views/MachineScrollViewPage'
import ParamScrollViewPage from '@/pages/views/ParamScrollViewPage'
import SLDViewPage from '@/pages/views/SLDViewPage'
import ConsolidatedViewPage from '@/pages/views/ConsolidatedViewPage'
import AQViewPage from '../pages/views/AQViewPage.vue'
export default {
  name:'EntureViews',
//   props:['view_id'],
  components:{},
  mounted() {
    document.title='Enture views'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }else{
        this.view_id=this.$route.params.id
      this.viewList=this.$store.state.views
    }
      // Check if there are saved views in local storage and use them
      // const savedViews = localStorage.getItem('views');
      // if (savedViews) {
      //   this.$store.commit('updateViews', JSON.parse(savedViews));
      // }
    },
    data(){
        return {
            view_id:null,
            viewHash:{
          'tableView':TableViewPage,
          'widgetView':WidgetViewPage,
          'dynamicTimeframedWidgetView':DynamicTimeframedWidgetView,
          'energyUsageView':EnergyUsageViewPage,
          'machineScrollView':MachineScrollViewPage,
          'paramScrollView':ParamScrollViewPage,
          'sldView':SLDViewPage,
          'consolidatedView':ConsolidatedViewPage,
          'aqView':AQViewPage,
        },
        }
    },
    computed:{
        view(){
            let view =null
            if(this.view_id){
                view=this.$store.getters.getViewById(this.view_id)
            }
            return view
        }
    },
    methods:{
        back(){
            history.back()
        }
    }
}
</script>